import {configureStore} from '@reduxjs/toolkit';
import { createManufacturerSlice,  createProductSlice, createRetailerSlice, createSupplierSlice,  getAllManufacturerSlice, getAllProductSlice, getAllSupplierSlice, getAllRetailerSlice,  getOneManufacturerSlice,  getOneProductSlice, getOneRetailerSlice, getOneSupplierSlice } from './Slices/marketMapTableSlices.js';
import { FilterBoxToggleToggleSlice, SideBarToggleSlice } from './Slices/sideBarSlice.js';
import { deleteDataInTableSlice, updateManufacturerSlice,  updateProductSlice, updateRetailerSlice, updateSupplierSlice } from './Slices/marketMapTableEditSlice.js';

import { createEmptyAttributeGroupSlice, getAllGroupAttributesSlice, getAllManufacturerAttributesSlice, getAllPackagingAttributesSlice, getAllProductAttributesSlice, getAllProgramAttributesSlice, getAllPromotionAttributesSlice, getAllRegionAttributesSlice, getAllRetailerAttributesSlice, getAllSupplierAttributesSlice, getOneGroupAttributesSlice, getOneManufacturerAttributesSlice, getOnePackagingAttributesSlice, getOneProductAttributesSlice, getOneProgramAttributesSlice, getOnePromotionAttributesSlice, getOneRegionAttributesSlice, getOneRetailerAttributesSlice, getOneSupplierAttributesSlice, updateAttributeGroupNameSlice, updateGroupAttributeGroupSlice,  updateManufacturerAttributeGroupSlice,  updatePackagingAttributeGroupSlice, updateProductAttributeGroupSlice,  updateProgramAttributeGroupSlice,  updatePromotionAttributeGroupSlice,  updateRegionAttributeGroupSlice,  updateRetailerAttributeGroupSlice,  updateSupplierAttributeGroupSlice } from './Slices/marketMapAttributeSlice.js';

import {DataSelectionNamesSlice, selectedAttributesSlice, selectedFilterTableAttributesSlice, selectedFilterTableQuerysSlice, selectedQuerysSlice} from './Slices/marketMapShortedDataSlice.js';

import { addManufacturerInProgramSlice, addProductInProgramSlice, addRetailerInProgramSlice, addSupplierInProgramSlice, createProgramSlice, deleteManufacturerInProgramSlice, deleteProductInProgramSlice, deleteProgramSlice, deleteRetailerInProgramSlice, deleteSupplierInProgramSlice, getAllFilteredRetailersByProgramSlice, getAllProgramSlice, getOneProgramSlice, updateProgramSlice } from './Slices/marketMapProgram.js';
import { authSlice, extractUserSlice, getAllUsersSlice, updateUserRoleSlice, userLoginSlice, userLogoutSlice, userPasswordSlice, userRegisterSlice, userTokenSlice, userValidateSlice } from './Slices/userAuthSlice.js';
import { deleteProgramImgSlice, uploadManufacturerImgSlice,  uploadProductImgSlice, uploadProgramImgSlice,   uploadRetailerImgSlice, uploadSupplierImgSlice } from './Slices/marketMapImageSlice.js';
import { getAllFilteredManufacturerSlice, getAllFilteredProductSlice, getAllFilteredProgramSlice, getAllFilteredRetailerSlice, getAllFilteredSupplierSlice } from './Slices/marketMapGetAllFilter.js';
import { getAllAPIFieldsNameSlice, getAllOperationSetupSlice, getAllStageSetupSlice, StageSetupSlice } from './TraceabilitySlice/stageSetup.js';
import { getBatchDataSlice, getCustomerPoSoSlice, getHUDataSlice, getSKUDataSlice, getTowelCustomerDataSlice} from './TraceabilitySlice/liveAPI.js';
import { liveAPISlice } from './TraceabilitySlice/liveVarData.js';



const store = configureStore({
    reducer : {

        StageSetup: StageSetupSlice.reducer, 
        AllStageSetup: getAllStageSetupSlice.reducer, 
        AllOperationSetup: getAllOperationSetupSlice.reducer, 
        AllAPIFieldsName: getAllAPIFieldsNameSlice.reducer, 
        BatchData: getBatchDataSlice.reducer, 
        TowelCustomerData: getTowelCustomerDataSlice.reducer, 
        CustomerPoSo: getCustomerPoSoSlice.reducer, 
        SKUData: getSKUDataSlice.reducer, 
        HUData: getHUDataSlice.reducer, 
        liveAPIVarData: liveAPISlice.reducer, 



        selectedQuerys: selectedQuerysSlice.reducer, 
        AllselectedAttributesHeading: selectedAttributesSlice.reducer,

        selectedFilterTableQuerys: selectedFilterTableQuerysSlice.reducer, 
        AllselectedFilterTableAttributesHeading: selectedFilterTableAttributesSlice.reducer,

        DataSelectionNames: DataSelectionNamesSlice.reducer,
        UserRegister : userRegisterSlice.reducer,
        UserToken : userTokenSlice.reducer,
        UserValidate : userValidateSlice.reducer,
        UserLogin : userLoginSlice.reducer,
        UserLogout : userLogoutSlice.reducer,
        UserPassword : userPasswordSlice.reducer,
        auth : authSlice.reducer,
        AllUsers : getAllUsersSlice.reducer,
        ExtractUser : extractUserSlice.reducer,
        UpdateUserRole : updateUserRoleSlice.reducer,
        
        sideBarToggle: SideBarToggleSlice.reducer,
        filterBoxToggle: FilterBoxToggleToggleSlice.reducer,

        GetAllFilteredProduct: getAllFilteredProductSlice.reducer,
        GetAllFilteredRetailer: getAllFilteredRetailerSlice.reducer,
        GetAllFilteredManufacturer: getAllFilteredManufacturerSlice.reducer,
        GetAllFilteredSupplier: getAllFilteredSupplierSlice.reducer,
        GetAllFilteredProgram: getAllFilteredProgramSlice.reducer,
        GetAllFilteredRetailersByProgram: getAllFilteredRetailersByProgramSlice.reducer,



        Product: createProductSlice.reducer,
        AllProducts : getAllProductSlice.reducer,
        OneProduct : getOneProductSlice.reducer,
        
        Retailer : createRetailerSlice.reducer,
        AllRetailers : getAllRetailerSlice.reducer,
        OneRetailer : getOneRetailerSlice.reducer,

        Manufacturer : createManufacturerSlice.reducer,
        AllManufacturers : getAllManufacturerSlice.reducer,
        OneManufacturer : getOneManufacturerSlice.reducer,
        
        Supplier : createSupplierSlice.reducer,
        AllSuppliers : getAllSupplierSlice.reducer,
        OneSupplier : getOneSupplierSlice.reducer,
        

        DeleteDataInTable : deleteDataInTableSlice.reducer,

        UpdateProduct : updateProductSlice.reducer,
        UpdateRetailer : updateRetailerSlice.reducer,
        UpdateManufacturer : updateManufacturerSlice.reducer,
        UpdateSupplier : updateSupplierSlice.reducer,



        CreateEmptyAttributeGroup : createEmptyAttributeGroupSlice.reducer,
        UpdateAttributeGroupName : updateAttributeGroupNameSlice.reducer,

        UpdateProductAttributeGroup : updateProductAttributeGroupSlice.reducer,
        AllProductAttributeGroup : getAllProductAttributesSlice.reducer,
        OneProductAttributesGroup : getOneProductAttributesSlice.reducer,

        UpdateRetailerAttributeGroup : updateRetailerAttributeGroupSlice.reducer,
        AllRetailerAttributeGroup : getAllRetailerAttributesSlice.reducer,
        OneRetailerAttributesGroup : getOneRetailerAttributesSlice.reducer,


        UpdateManufacturerAttributeGroup : updateManufacturerAttributeGroupSlice.reducer,
        AllManufacturerAttributeGroup : getAllManufacturerAttributesSlice.reducer,
        OneManufacturerAttributesGroup : getOneManufacturerAttributesSlice.reducer,


        UpdateSupplierAttributeGroup : updateSupplierAttributeGroupSlice.reducer,
        AllSupplierAttributeGroup : getAllSupplierAttributesSlice.reducer,
        OneSupplierAttributesGroup : getOneSupplierAttributesSlice.reducer,
        

        UpdatePackagingAttributeGroup : updatePackagingAttributeGroupSlice.reducer,
        AllPackagingAttributeGroup : getAllPackagingAttributesSlice.reducer,
        OnePackagingAttributesGroup : getOnePackagingAttributesSlice.reducer,


        UpdatePromotionAttributeGroup : updatePromotionAttributeGroupSlice.reducer,
        AllPromotionAttributeGroup : getAllPromotionAttributesSlice.reducer,
        OnePromotionAttributesGroup : getOnePromotionAttributesSlice.reducer,


        UpdateProgramAttributeGroup : updateProgramAttributeGroupSlice.reducer,
        AllProgramAttributeGroup : getAllProgramAttributesSlice.reducer,
        OneProgramAttributesGroup : getOneProgramAttributesSlice.reducer,


        UpdateRegionAttributeGroup : updateRegionAttributeGroupSlice.reducer,
        AllRegionAttributeGroup : getAllRegionAttributesSlice.reducer,
        OneRegionAttributesGroup : getOneRegionAttributesSlice.reducer,


        UpdateGroupAttributeGroup : updateGroupAttributeGroupSlice.reducer,
        AllGroupAttributeGroup : getAllGroupAttributesSlice.reducer,
        OneGroupAttributesGroup : getOneGroupAttributesSlice.reducer,



        Program : createProgramSlice.reducer,
        AllPrograms : getAllProgramSlice.reducer,
        UpdateProgram  : updateProgramSlice.reducer,
        OneProgram  : getOneProgramSlice.reducer,
        DeleteProgram  : deleteProgramSlice.reducer,

        RetailerInProgram : addRetailerInProgramSlice.reducer,
        ManufacturerInProgram : addManufacturerInProgramSlice.reducer,
        SupplierInProgram : addSupplierInProgramSlice.reducer,
        ProductInProgram : addProductInProgramSlice.reducer,


        DeleteRetailerInProgram : deleteRetailerInProgramSlice.reducer,
        DeleteManufacturerInProgram : deleteManufacturerInProgramSlice.reducer,
        DeleteSupplierInProgram : deleteSupplierInProgramSlice.reducer,
        DeleteProductInProgram : deleteProductInProgramSlice.reducer,



        
        UploadProductImg : uploadProductImgSlice.reducer,

        UploadRetailerImg : uploadRetailerImgSlice.reducer,

        UploadManufacturerImg : uploadManufacturerImgSlice.reducer,

        UploadSupplierImg : uploadSupplierImgSlice.reducer,

        UploadProgramImg : uploadProgramImgSlice.reducer,
        DeleteProgramImg : deleteProgramImgSlice.reducer,



    }
});



export default store;