import { createAsyncThunk} from "@reduxjs/toolkit";
import axios from 'axios';
import createApiSlice from "../Slices/Common/CommoCreateSlice";
import config from '../../config';

const serviceURL = config.SERVICE_TRACEABILITY_URL;

 

// Get all getBatchData
export const getBatchData = createAsyncThunk( "getBatchData/get", async ( data ,{ rejectWithValue })=>{
    try {
        const config = { headers: {"Content-Type": "application/json" } };

        const responce = await axios.post(`${serviceURL}/batch/towel-Batch-Trace`,data,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


const getBatchDataSlice = createApiSlice('getBatchData', getBatchData , true);


// Get all Towel Customer list
export const getTowelCustomerData = createAsyncThunk( "getTowelCustomerData/get", async ( {id = null} ,{ rejectWithValue })=>{
    try {
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL}/batch/get-customer-list`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getTowelCustomerDataSlice = createApiSlice('getTowelCustomerData', getTowelCustomerData , true);


// Get all Customer PO and SO list
export const getCustomerPoSo = createAsyncThunk( "getCustomerPoSoData/get", async ( {data} ,{ rejectWithValue })=>{
    try {
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/batch/customer-so-header`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getCustomerPoSoSlice = createApiSlice('getCustomerPoSoData', getCustomerPoSo , true);


// Get all SKU form the PO and SO number
export const getSKUData = createAsyncThunk( "getallSKUData/get", async ( data ,{ rejectWithValue })=>{
    try {
        const config = {
            headers: {"Content-Type": "application/json" }, 
            params: {vbeln : data}
       };

        const responce = await axios.get(`${serviceURL}/batch/towelCustomerSOItems`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getSKUDataSlice = createApiSlice('getallSKUData', getSKUData , true);




// Get all HU form the PO and SO number and SKU number
export const getHUData = createAsyncThunk( "getallHUData/get", async ( {data} ,{ rejectWithValue })=>{
    try {
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/batch/towel-so-sku-hu`,data , config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getHUDataSlice = createApiSlice('getallHUData', getHUData , true);





 

export {
    getBatchDataSlice,
    getTowelCustomerDataSlice,
    getCustomerPoSoSlice,
    getSKUDataSlice,
    getHUDataSlice
}

export const {resetState : resetGetBatchData} = getBatchDataSlice.actions;
export const {resetState : resetGetTowelCustomerData} = getTowelCustomerDataSlice.actions;
export const {resetState : resetGetCustomerPoSo} = getCustomerPoSoSlice.actions;
export const {resetState : resetGetSKUData} = getSKUDataSlice.actions;
export const {resetState : resetGetHUData} = getHUDataSlice.actions;



