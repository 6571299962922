import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import createApiSlice from "../Slices/Common/CommoCreateSlice";
import config from '../../config';

const serviceURL = config.SERVICE_TRACEABILITY_URL;

 

// =============================================== StageSetup APIS ============================================
const StageSetupSlice = createSlice({
    name : "StageSetup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetStageSetupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(createStageSetup.pending, updateStageSetup.pending, deleteStageSetup.pending , createOperationSetup.pending , updateOperationSetup.pending , deleteOperationSetup.pending , createAPIFieldsName.pending , updateAPIFieldsName.pending , deleteAPIFieldsName.pending),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(createStageSetup.fulfilled, updateStageSetup.fulfilled, deleteStageSetup.fulfilled , createOperationSetup.fulfilled , updateOperationSetup.fulfilled , deleteOperationSetup.fulfilled , createAPIFieldsName.fulfilled , updateAPIFieldsName.fulfilled , deleteAPIFieldsName.fulfilled),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(createStageSetup.rejected, updateStageSetup.rejected, deleteStageSetup.rejected , createOperationSetup.rejected , updateOperationSetup.rejected , deleteOperationSetup.rejected ,  createAPIFieldsName.rejected , updateAPIFieldsName.rejected , deleteAPIFieldsName.rejected),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
}); 



// Create Satge Setup
export const createStageSetup = createAsyncThunk( "StageSetup/create", async ( data, { rejectWithValue })=>{
    try {
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/stage/createStage-setup`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

  
// update StageSetup 
export const updateStageSetup = createAsyncThunk( "StageSetup/update", async (  data , { rejectWithValue })=>{
    try {
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL}/stage/updateStageById/${data.id}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete StageSetup 
export const deleteStageSetup = createAsyncThunk( "StageSetup/delete", async ( data, { rejectWithValue})=>{
    try {
                
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.delete(`${serviceURL}/stage/deleteStageById/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


 
// Get all getAllStageSetup
export const getAllStageSetup = createAsyncThunk( "StageSetup/getAll", async ( {id=null} ,{ rejectWithValue })=>{
    try {
        const config = { headers: { "Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL}/stage/getAllStages`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getAllStageSetupSlice = createApiSlice('getAllStageSetup', getAllStageSetup , true);



  
// ========================================== Operation ================================================ 



// Create Operation Setup
export const createOperationSetup = createAsyncThunk( "OperationSetup/create", async ( data, { rejectWithValue })=>{
    try {
        const { stageId, ...restData } = data;

        const config = {
             headers: {"Content-Type": "application/json" }, 
             params: { stageId}
        };

        const responce = await axios.post(`${serviceURL}/operation/createOperation`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
  
// update OperationSetup 
export const updateOperationSetup = createAsyncThunk( "OperationSetup/update", async (  data , { rejectWithValue })=>{
    try {
        const { operationId, ...restData } = data;
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL}/operation/updateOperationById/${operationId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete OperationSetup 
export const deleteOperationSetup = createAsyncThunk( "OperationSetup/delete", async ( data, { rejectWithValue})=>{
    try {
                
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.delete(`${serviceURL}/operation/deleteOperationById/${data.Id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

 
// Get all getAllOperationSetup
export const getAllOperationSetup = createAsyncThunk( "OperationSetup/getAll", async ( {id=null} ,{ rejectWithValue })=>{
    try {
        const config = { headers: { "Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL}/operation/getAllOperations`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


const getAllOperationSetupSlice = createApiSlice('getAllOperationSetup', getAllOperationSetup , true);




  
// ========================================== API Fields Name ================================================ 

// Create API Fields Name
export const createAPIFieldsName = createAsyncThunk( "APIFieldsName/create", async ( data, { rejectWithValue })=>{
    try {
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/batch/createProductFields`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

  
// update API Fields Name 
export const updateAPIFieldsName = createAsyncThunk( "APIFieldsName/update", async (  data , { rejectWithValue })=>{
    try {
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL}/batch/updateProductById/${data.id}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete API Fields Name 
export const deleteAPIFieldsName = createAsyncThunk( "APIFieldsName/delete", async ( data, { rejectWithValue})=>{
    try {
                
        const config = { headers: {"Content-Type": "application/json" }};

        const responce = await axios.delete(`${serviceURL}/batch/deleteProductById/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

 
// Get all API Fields Name
export const getAllAPIFieldsName = createAsyncThunk( "APIFieldsName/getAll", async ( {id=null} ,{ rejectWithValue })=>{
    try {
        const config = { headers: { "Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL}/batch/getAllProductFields`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getAllAPIFieldsNameSlice = createApiSlice('getAllAPIFieldsName', getAllAPIFieldsName , true);






  




 

export {
    StageSetupSlice,
    getAllStageSetupSlice,
    getAllOperationSetupSlice,
    getAllAPIFieldsNameSlice
}

export const {resetStageSetupState} = StageSetupSlice.actions;
export const {resetState : resetGetAllStageSetup} = getAllStageSetupSlice.actions;
export const {resetState : resetGetAllOperationSetup} = getAllOperationSetupSlice.actions;
export const {resetState : resetGetAllAPIFieldsName} = getAllAPIFieldsNameSlice.actions;



