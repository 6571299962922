// In your liveAPI.js slice file
import { createSlice } from '@reduxjs/toolkit';

const liveAPISlice = createSlice({
  name: 'liveAPI',
  initialState :  {
    selectedHUNumber: "",   
  },
  reducers: {
    // other reducers

    // Add this reducer to update the selected HU number
    setSelectedHUNumber(state, action) {
      state.selectedHUNumber = action.payload;
    },
  },
});

// Export the action
export const { setSelectedHUNumber } = liveAPISlice.actions;

export {liveAPISlice};
