import React from 'react';
import '../../index.css'; 

export const InputBox = ({ labelName, DataType, labelValue, handleFun ,value , error}) => {
  return (
    <label className="inpBox">
      <input type={DataType} placeholder="&nbsp;" name={labelValue} value={value} onChange={handleFun} required/>
      <span className="label">{labelName}</span>
      <label className="focus-bg"></label>
      {error && <p className="error_message">* {error}</p>}
    </label>
  );
};

export const TextAreaBox = ({ labelName, DataType, labelValue, handleFun , value , error }) => {
  return (
    <label className="inpBox">
      <textarea type={DataType} placeholder="&nbsp;" name={labelValue} value={value} onChange={handleFun} required/>
      <span className="label">{labelName}</span>
      <label className="focus-bg"></label>
      {error && <p className="error_message">* {error}</p>}
    </label>
  );
};

export const DropdownBox = ({ labelName, options, selectedValue, handleSelect, name , error}) => {
  return (
    <label className="inpBox">
      <select name={name} value={selectedValue} onChange={handleSelect} required>
        <option value="" ></option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <span className="label">{labelName}</span>
      <label className="focus-bg"></label>
      {error && <p className="error_message">* {error}</p>}
    </label>
  );
};


